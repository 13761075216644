import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/img/scan_qrcode.svg'


const _hoisted_1 = { class: "pageContainer bg_lightGrey hostessScan py-5 px-3" }
const _hoisted_2 = { class: "scan" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = { class: "stream" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_qr_scan = _resolveComponent("qr-scan")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          localizedKey: "app_ScanForWelcomeKit",
          text: "Scan for welcome kit",
          type: "h1",
          class: "hostessTitle"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_qr_scan, { callback: _ctx.callback }, null, 8, ["callback"])
      ])
    ])
  ]))
}